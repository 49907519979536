body { background-color: #ffffff; }
body { color: var(--clr-2389); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-2389);
font-family: 'Oswald';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-2389);
font-family: 'Oswald';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-2389);
font-family: 'Oswald';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-2389);
font-family: 'Oswald';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: var(--clr-2389);
font-family: 'Oswald';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-2389);
font-family: 'Oswald';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-2389);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Oswald';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-2390);}
/* Primary:3 */
.MES3 {
background-color: var(--clr-2391);
color: #ffffff;
border-radius: 50px;
border-width: 1px;
border-style: solid;
 }
/* Primary:4 */
.MES4 {
background-color: var(--clr-2390);
color: #ffffff;
 }
.MES4 {
background-color: var(--clr-2390);
color: #ffffff;
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: #ffffff;
 }
 }
cite.MEC4{
color: #ffffff;
}
/* Secondary:5 */
.MES5 {
background-color: var(--clr-2392);
color: #ffffff;
 }
/* Secondary:6 */
.MES6 {
background-color: var(--clr-2392);
color: #ffffff;
 }
.MES6 {
background-color: var(--clr-2392);
color: #ffffff;
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: #ffffff;
 }
 }
cite.MEC6{
color: #ffffff;
}
/* Dark:7 */
.MES7 {
background-color: var(--clr-2393);
color: #ffffff;
 }
/* Dark:8 */
.MES8 {
background-color: var(--clr-2393);
color: #ffffff;
 }
.MES8 {
background-color: var(--clr-2393);
color: #ffffff;
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: #ffffff;
 }
 }
a.MEC8 { color: var(--clr-2394);
&:hover { color: var(--clr-2392);}
 }
cite.MEC8{
color: #ffffff;
}
/* Light:9 */
.MES9 {
background-color: var(--clr-2394);
color: var(--clr-2389);
 }
/* Light:10 */
.MES10 {
background-color: var(--clr-2394);
color: var(--clr-2389);
 }
.MES10 {
background-color: var(--clr-2394);
color: var(--clr-2389);
h1.MEC10, h2.MEC10, h3.MEC10, h4.MEC10, h5.MEC10, h6.MEC10 { color: var(--clr-2389);
 }
 }
cite.MEC10{
color: var(--clr-2389);
}
/* Shade 1:11 */
.MES11 {
background-color: var(--clr-2395);
color: #ffffff;
 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-2395);
color: #ffffff;
 }
.MES12 {
background-color: var(--clr-2395);
color: #ffffff;
h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: #ffffff;
 }
 }
cite.MEC12{
color: #ffffff;
}
/* Menu:13 */
.MES13 {
background-color: var(--clr-2391);
color: var(--clr-2393);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
min-height:50px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 100px;};
padding: 10px;

border-width: 0 0 3px 0;
border-style: solid;
border-color: transparent transparent var(--clr-2394) transparent;
 }
.MES13 {
background-color: var(--clr-2391);
color: var(--clr-2393);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
min-height:50px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 100px;};
padding: 10px;

border-width: 0 0 3px 0;
border-style: solid;
border-color: transparent transparent var(--clr-2394) transparent;
 }
a.MEC13 { color: var(--clr-2393);
&:hover { color: var(--clr-2393);}
 }
cite.MEC13{
color: var(--clr-2393);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
}
/* home block blue:14 */
.MES14 {
background-color: var(--clr-2392);
&:hover, &.hover { background-color: var(--clr-2393);}
& > .underlay, & > .inner-overlay { opacity: 0.25 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://mdh.com.au/img/379/31');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:250px;
@media #{$medium-up} {
min-height: 350px;};
@media #{$large-up} {
min-height: 450px;};
padding: 20px;

@media #{$medium-up} {
padding: 25px 40px;

}
@media #{$large-up} {
padding: 30px 50px;

}
 }
.MES14 {
background-color: var(--clr-2392);
&:hover, &.hover { background-color: var(--clr-2393);}
& > .underlay, & > .inner-overlay { opacity: 0.25 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://mdh.com.au/img/379/31');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:250px;
@media #{$medium-up} {
min-height: 350px;};
@media #{$large-up} {
min-height: 450px;};
padding: 20px;

@media #{$medium-up} {
padding: 25px 40px;

}
@media #{$large-up} {
padding: 30px 50px;

}
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: #ffffff;
 }
 }
.MEC14 li {color: #ffffff;}
cite.MEC14{
color: #ffffff;
}
/* :15 */
.MES15 {
background-color: var(--clr-2391);
color: var(--clr-2393);
border-width: 5px 0 0 0;
border-style: solid;
border-color: var(--clr-2392) transparent transparent transparent;
 }
.MES15 {
background-color: var(--clr-2391);
color: var(--clr-2393);
border-width: 5px 0 0 0;
border-style: solid;
border-color: var(--clr-2392) transparent transparent transparent;
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: var(--clr-2393);
 }
 }
.MEC15 li {color: var(--clr-2393-flat);}
cite.MEC15{
color: var(--clr-2393);
}
/* Hollaw:16 */
.MES16 {
color: var(--clr-2391);
&:hover { color: var(--clr-2391);}
border-width: 1px;
border-style: solid;
border-color: var(--clr-2391);
&:hover { border-color: var(--clr-2391); }
 }
/* :17 */
.MES17 {
& .slider-arrow {font-size: 0px;
@media #{$medium-up} {
font-size: 80px;
};
@media #{$large-up} {
font-size: 100px;
};
} }
/* Contents:18 */
.MES18 {
background-color: var(--clr-2391);
&:hover, &.hover { background-color: var(--clr-2391);}
color: var(--clr-2393);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
 }
.MES18 {
background-color: var(--clr-2391);
&:hover, &.hover { background-color: var(--clr-2391);}
color: var(--clr-2393);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
 }
a.MEC18 { color: var(--clr-2393);
&:hover { color: var(--clr-2393);}
 }
cite.MEC18{
color: var(--clr-2393);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
}
/* Slider text 2:19 */
.MES19 {
 }
.MES19 {
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: var(--clr-2391);
 }
 }
/* Footer Dark:20 */
.MES20 {
background-color: var(--clr-2392);
color: #ffffff;
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
 }
.MES20 {
background-color: var(--clr-2392);
color: #ffffff;
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: #ffffff;
 }
 }
a.MEC20 { color: var(--clr-2394);
&:hover { color: var(--clr-2395);}
 }
cite.MEC20{
color: #ffffff;
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
}
/* News grid:21 */
.MES21 {
color: var(--clr-2393);
border-width: 1px;
border-style: solid;
border-color: #ffffff var(--clr-2394);
 }
.MES21 {
color: var(--clr-2393);
border-width: 1px;
border-style: solid;
border-color: #ffffff var(--clr-2394);
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: var(--clr-2392);
 }
 }
cite.MEC21{
color: var(--clr-2393);
}
/* About FAQ:22 */
details.MES22 {
& > summary{background-color: var(--clr-2394);
}
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-2391) transparent;
} }
/* Home FAQ:23 */
details.MES23 {
& > summary{background-color: var(--clr-2390);
}
color: var(--clr-2391);
& > article { color: var(--clr-2391);
 }
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-2391) transparent;
} }
/* Main Menu:24 */
nav.me-Menu.MES24 {
& .menu-item.MEC24, & .menu-item.MEC24 > div.MEC24{ & > a.MEC24{color: var(--clr-2392);
font-size: 14px;
@media #{$medium-up} {
font-size: 15.4px;
}@media #{$large-up} {
font-size: 16.8px;
}text-transform: uppercase;
}
 &:hover > a.MEC24{color: var(--clr-2390);
}
 }
&.horizontal > .menu-item.MEC24 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC24 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC24 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC24 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 0 15px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Home panel grey:25 */
.MES25 {
background-color: var(--clr-2394);
&:hover, &.hover { background-color: var(--clr-2395);}
color: var(--clr-2389);
padding: 15px;

@media #{$medium-up} {
padding: 25px 40px;

}
@media #{$large-up} {
padding: 30px 50px;

}
 }
.MES25 {
background-color: var(--clr-2394);
&:hover, &.hover { background-color: var(--clr-2395);}
color: var(--clr-2389);
padding: 15px;

@media #{$medium-up} {
padding: 25px 40px;

}
@media #{$large-up} {
padding: 30px 50px;

}
h1.MEC25, h2.MEC25, h3.MEC25, h4.MEC25, h5.MEC25, h6.MEC25 { color: var(--clr-2389);
 }
 }
cite.MEC25{
color: var(--clr-2389);
}
/* 3 bocks homepage:26 */
.MES26 {
background-color: var(--clr-2392);
&:hover, &.hover { background-color: var(--clr-2393);}
& > .underlay, & > .inner-overlay { opacity: 0.25 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://mdh.com.au/img/379/31');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:250px;
@media #{$medium-up} {
min-height: 250px;};
@media #{$large-up} {
min-height: 250px;};
padding: 20px;

@media #{$medium-up} {
padding: 25px 40px;

}
@media #{$large-up} {
padding: 30px 50px;

}
 }
.MES26 {
background-color: var(--clr-2392);
&:hover, &.hover { background-color: var(--clr-2393);}
& > .underlay, & > .inner-overlay { opacity: 0.25 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://mdh.com.au/img/379/31');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:250px;
@media #{$medium-up} {
min-height: 250px;};
@media #{$large-up} {
min-height: 250px;};
padding: 20px;

@media #{$medium-up} {
padding: 25px 40px;

}
@media #{$large-up} {
padding: 30px 50px;

}
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: #ffffff;
 }
 }
.MEC26 li {color: #ffffff;}
cite.MEC26{
color: #ffffff;
}
/* :28 */
.MES28 {
padding: 15px;

 }
/* :29 */
.MES29 {
padding: 15px;

 }
.MES29 {
padding: 15px;

 }
/* line border:30 */
.MES30 {
background-color: var(--clr-2394);
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 5px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-2392);
 }
.MES30 {
background-color: var(--clr-2394);
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 5px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-2392);
 }
/* no vis separator:31 */
.MES31 {
 }
/* home new2023:32 */
.MES32 {
background-color: var(--clr-2394);
border-radius: 500px;
-webkit-transform: translateZ(0);
overflow: hidden;
 }
.MES32 {
background-color: var(--clr-2394);
border-radius: 500px;
-webkit-transform: translateZ(0);
overflow: hidden;
 }
/* trial001:33 */
.MES33 {
background-color: var(--clr-2394);
&:hover {background-color: var(--clr-2395);}
color: var(--clr-2395);
&:hover { color: transparent;}
 }
/* Page Accordion:34 */
details.MES34 {
 }
